import React from 'react'
import { graphql, Link } from "gatsby";
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import bodykomImg from "../img/bodykom.png";
import aktieagarbrev from "../docs/aktieagarbrev.pdf";
import villkor from "../docs/villkor-anvisningar.pdf";
import anmalan from "../docs/anmalningssedel-kiwok.pdf";
import memorandum from "../docs/investerings-memorandum.pdf";
import pitch from "../docs/kiwok-investeringspitch.pdf";

import hjartdoktorn from "../img/Hjartdoktorn_logo_001.png";
import hjartsensor from "../img/BodyKom_Device.png";
import individ from "../img/Individanpassad_Icon.png";
import hallbar from "../img/Hallbar_Icon.png";
import inkluderande from "../img/Inkluderande_Icon.png";
import kiwokWhite from "../img/Kiwok_Logo_White.png";
import pdfIcon from "../img/PDF_Icon.png";

//import styles from '../components/base.css'

class nyemission extends React.Component{
    render(){
        const siteTitle = ('Kiwok - Nyemission')

        return (
            <Layout location={this.props.location} >
            <div style={{ background: '#edeef2' }}>
              <Helmet title={siteTitle} />
              
            <div className="emission">
                <div className="container">
                <div className="intro-text-half-page">
                    <h1 className="hIntro emission-h1">TECKNA AKTIER I KIWOK</h1>
                    <p className="pIntro">Ta chansen 3 februari t.o.m den 21 februari 2025 att vara med på vår resa att hjälpa hälsomedvetna att ta kontroll över sin hälsa.</p>
                    <a href="#emission" className="btn-light" to="/nyemission">Om Nyemissionen</a>
                    {/*<Link className="btn-light" to="/arsstamma">Om Årsstämman</Link>*/}
                </div>
                <div className="intro-text-half-page"> 
                    <div className="img-placeholder"></div>
                </div>
                </div>
            </div>

            {/*<div className="container-full-width">
                <div className="container" >
                    <div className="intro-text-half-page"> 
                        <img className ="half-img" src={hjartdoktorn}></img>
                    </div>
                    <div className="intro-text-half-page">
                        <p style={{ color: '#000' }}>Nu lanserar vi <b>HjärtDoktorn™</b>. En tjänst som riktar sig direkt till patienten och som underlättar för alla att hålla koll på sitt hjärta.<br/><br/>
                        <b>För patienten</b> leder tjänsten till tidigare upptäckt av hjärtproblem och tidigare signaler på att kroppen behöver återhämta sig.<br/><br/>
                        <b>För vården</b> innebär tjänsten att man i tid får ett bättre beslutsunderlag och ett effektivare och mer personligt patientbemötande.<br/><br/>
                        <b>För samhället</b> innebär tjänsten en friskare befolkning och en mer hållbar vård.</p>
                    </div>
                </div>
            </div>*/}

            <div className="container-full-width" style={{ background: '#adcaea' }}>
                <div className="container">
                    <div className="intro-text-half-page">
                        <h2><b>En av världens mest avancerade hjärtsensorer</b></h2>
                        <p style={{ color: '#000' }}>Det pågår en trend mot mer vård i hemmet via hemmonitorering, videosamtal och mobila hälsoteam.<br/><br/>
                        Den största kostnaden för sjukvården idag är att vårda kroniskt sjuka patienter med t.ex. hjärtsvikt, KOL och diabetes.<br/><br/>
                        Dessa patientgrupper som har stort behov av vård kan ofta med viss hjälp på plats behandlas och monitoreras från hemmet vilket minskar beläggningen på sjukhusen och behovet av sjuktransporter.<br/><br/>
                        Vi behöver ställa om vården och minska behovet av resurser för att vårda de redan sjuka genom att tidigt sätta in preventiva insatser som minskar ohälsa och lösgör resurser.</p>
                    </div>
                    <div className="intro-text-half-page"> 
                      <img style={{ maxWidth: '55%'}} className ="half-img hjartsensor" src={hjartsensor}></img>
                    </div>
                </div>
            </div>
            
            <div className="wrapper1" style={{paddingTop: '50px'}}>
                <div className="wrapper-child-emission">
                  <img 
                    style={{width: '60px', height: 'auto'}}
                    className="about-img"
                    src={individ}
                    alt="Bodykom"
                  ></img>
                  <h3 className="boldedStart" style={{ textAlign: 'center' }}>Individanpassad</h3>
                  <p style={{ textAlign: 'center' }}>Du får en vård som är direkt anpassad till dina behov och förutsättningar.</p>
                </div>
                <div className="wrapper-child-emission">
                  <img
                    style={{width: '60px', height: 'auto'}}
                    className="about-img"
                    src={hallbar}
                    alt="Bodykom"
                  ></img>
                  <h3 className="boldedStart" style={{ textAlign: 'center' }}>Hållbar</h3>
                  <p style={{ textAlign: 'center' }}>Vård på distans kräver färre resurser. Tidigare diagnos minskar behovet av behandling.</p>
                </div>
                <div className="wrapper-child-emission">
                  <img
                    style={{width: '80px', height: 'auto'}}
                    className="about-img"
                    src={inkluderande}
                    alt="Bodykom"
                  ></img>
                  <h3 className="boldedStart" style={{ textAlign: 'center', marginTop: '4px' }}>Inkluderande</h3>
                  <p style={{ textAlign: 'center' }}>God och nära vård, tillgänglig för alla.</p>
                </div>
            </div>

            <div className="container-full-width" style={{ background: '#266de1' }}>
                <div className="container" >
                    <div className="intro-text-half-page"> 
                      <img style={{ maxWidth: '60%', paddingLeft: '10%'}} className ="half-img" src={kiwokWhite}></img>
                    </div>
                    <div className="intro-text-half-page">
                        <p style={{ color: '#fff' }}>Kiwok är ett svenskt medicintekniskt företag grundat 2003. 
                        Tillsammans med akademin utvecklar vi nästa generation lösningar för trendmonitorering av patientens vitala hälsoparametrar. 
                        BodyKom, som vid lanseringen enligt Gartner var den första mobilt uppkopplade hälsolösningen, är en global vårdtjänst för kontinuerlig distnansmonitorering
                         av patienters hälsoutveckling under längre tid. Bodykom leder till tidigare upptäckt och diagnos samt säkerställer uppföljning och återhämtning. 
                         Kiwoks lösningar ger en hållbar vård och personcentrerade vårdprocess.</p>
                    </div>
                </div>
            </div>

            <div  id="emission" className="container-full-width" style={{ background: '#adcaea' }}>
                <div className="container" >
                    <h3 className="boldedStart">Erbjudandet i sammandrag</h3>
                    <p className="p-text" style={{ color: '#000' }}>Styrelsen i Kiwok Nordic AB (publ) har fattat beslut om en nyemission av B-aktier med företrädesrätt för nuvarande aktieägare.</p><br/>
                    - <b>Emissionsbelopp:</b> Högst 11 217 550 kr.<br/>
                    - <b>Antal aktier i emissionen:</b> Högst 5 608 775 B-aktier.<br/>
                    - <b>Pris per aktie:</b> 2,00 kronor.<br/>
                    - <b>Avstämningsdag:</b> 30 januari 2025.<br/>
                    - <b>Teckningstid:</b> Från den 2 februari t.o.m. den 21 februari 2025.<br/>
                    - <b>Bolagsvärdering:</b> Cirka 56 MSEK (pre-money).<br/>
                    - <b>Företrädesrätt:</b> Nyemissionen sker med företrädesrätt för nuvarande aktieägare varvid fyra (4) befintliga aktier, oavsett aktieslag, ger rätt till teckning av en (1) ny aktie av serie B.<br/>
                    - <b>Teckning utan företrädesrätt:</b> I det fall samtliga B-aktier inte tecknas med stöd av teckningsrätter ska styrelsen, inom ramen för emissionens högsta belopp, besluta om tilldelning av B-aktier tecknade utan stöd av teckningsrätter, varvid tilldelning i första hand ska ske till dem vilka även tecknat B-aktier med stöd av teckningsrätter och, för det fall dessa inte kan erhålla full tilldelning, tilldelning ske i förhållande till deras teckning med stöd av teckningsrätter, och för det fall detta inte är möjligt, genom lottning, och tilldelning i andra hand ska ske till annan som tecknat B-aktier i nyemissionen utan stöd av teckningsrätter, och för det fall dessa inte kan erhålla full tilldelning, tilldelning ske i förhållande till det antal B-aktier som var och en har tecknat och, om detta inte är möjligt, genom lottning.<br/>
                    - <b>Handel i aktien:</b> Aktien i Kiwok är idag onoterad men bolaget kommer ansöka om listing av bolagets aktie på NGM Nordic SME, så snart bolaget uppfyller kraven.<br/>
                    {/*- <b>Emissioninstitut:</b> Hagberg & Aneborn Fondkomission AB, hagberganeborn.se</p>*/}
                </div>
            </div>
            <div className="container-full-width" style={{ background: '#adcaea', padding: '0' }}>
            <div className="wrapper1">
              {/* <div className="wrapper-child-emission">
                <a href={pitch} target="_blank"><img
                    style={{width: '90px', height: 'auto'}}
                    className="about-img"
                    src={pdfIcon}
                    alt="Bodykom"
                  ></img></a>
                  <p style={{ textAlign: 'center' }}>Investeringspitch 2024</p>
                </div> */}
                <div className="wrapper-child-emission">
                <a href={villkor} target="_blank"><img
                    style={{width: '90px', height: 'auto'}}
                    className="about-img"
                    src={pdfIcon}
                    alt="Bodykom"
                  ></img></a>
                  <p style={{ textAlign: 'center' }}>Villkor och anvisningar</p>
                </div>
                <div className="wrapper-child-emission">
                <a href={memorandum} target="_blank"><img
                    style={{width: '90px', height: 'auto'}}
                    className="about-img"
                    src={pdfIcon}
                    alt="Bodykom"
                  ></img></a>
                  <p style={{ textAlign: 'center' }}>Investeringsmemorandum</p>
                </div>
                <div className="wrapper-child-emission">
                  <a href={aktieagarbrev} target="_blank"><img
                    style={{width: '90px', height: 'auto'}}
                    className="about-img"
                    src={pdfIcon}
                    alt="Bodykom"
                  ></img></a>
                  <p style={{ textAlign: 'center' }}>Brev till aktieägarna</p>
                </div>
                <div className="wrapper-child-emission">
                  <a href={anmalan} target="_blank"><img
                    style={{width: '90px', height: 'auto'}}
                    className="about-img"
                    src={pdfIcon}
                    alt="Bodykom"
                  ></img></a>
                  <p style={{ textAlign: 'center' }}>Anmälningssedel</p>
                </div>
            </div>
            </div>
            </div>
          </Layout>
        )
    }
}

export default nyemission